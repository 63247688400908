import { ref } from 'vue'
const useWithdrawStatusEnable = (rows: any) => {//撤销
    const arr = ['F&L Approved', 'Submitted']
    const flag = ref<boolean>(false)
    flag.value = rows.every((item: any) => {
       return arr.indexOf(item.status) !== -1
    })
    return flag
};
export default useWithdrawStatusEnable
