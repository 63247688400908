
import axios from 'axios'
import { defineComponent, ref, computed,reactive,watch,onMounted } from 'vue'
import { VerticalAlignBottomOutlined } from '@ant-design/icons-vue'
import { delAttachment } from "@/API/payment";
import { FileItem } from '@/API/types';
import downloadFile from "@/utils/rv/downloadFile";
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue'
import useMBAFCOverview from "@/hooks/payment/useMBAFCOverview";
import * as API from "@/API/payment";
import { useStore } from 'vuex'
const {getUploadFileData} = useMBAFCOverview()
const attachmentColumns = [
  { title: "Attachment", dataIndex: "fileName", width: 200, slots: { customRender: "comments" }},
  { title: "Upload Date", dataIndex: "updatedAt", width: 110 },
  { title: "Operation", key: "operation", width: 165, slots: { customRender: "action"}}
]

export default defineComponent({
  emits: ["close-upload-visible"],
  props: {
    isUploadVisible: {
      type: Boolean,
      required: true,
      default: false
    },
    payoutCode: {
      type: String,
      required: true,
      default: ''
    },
    payoutRound: {
      type: String,
      required: true,
      default: ''
    },
    attachmentData: {
      type: Array,
      required: true,
      default: ()=>{[]}
    },
    paymentId: {
      type: String,
      required: true,
      default: ''
    },
    payStatus:{
      type: String,
      required: true,
      default: ''
    }
  },
  setup(props, context) {
    const { state } = useStore()

    const myAttachmentData = ref<null | any[]>(null)
    getUploadFileData(props.paymentId).then((res) => {
      myAttachmentData.value = res
    })
    onMounted(() => {
     //rrrr
    })
    // 文件上传
    const uploading = ref<boolean>(false);
    const isLt5M = ref<boolean[]>([]);
    const uploadComplete = ref<boolean>(true);
    // 获取标题内容
    const title = computed(() => `Upload Attachment File`)
    // // 设置抽屉显示状态
    const modal = (props as any).isUploadVisible;
    const roleVal = (props as any).curRole;
    const close = () => {
      context.emit('close-upload-visible', { isUploadVisible: false })
    }
    // 上传前
    const beforeUpload = (file: FileItem) => {
      console.log('进入上传前方法中。。。。。。。')
      /*const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isXlsx) {
        message.error('只能上传xlsx格式文件!')
      }*/
      isLt5M.value.push(file.size / 1024 / 1024 < state.payment.maxFileSize)
      /*if (file.size / 1024 / 1024 > 5) {
        message.error('图片不得大于5MB!')
      }*/
      return false
    }
    const fileList = ref<FileItem[]>([]);
    const handleRemove = (file: FileItem) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
      isLt5M.value = []
      //console.log('isLt5m===============',isLt5M)
      fileList.value.forEach((item: FileItem)=>{
        isLt5M.value.push(item.size / 1024 / 1024 < state.payment.maxFileSize)
      })
    };
    const submitFile = () => {
      console.log('上传的附件个数为：',fileList.value.length)
      const formData = new FormData();
      console.log(props.payStatus, 'props.payStatus')
      if(props.payStatus !== 'Created'){
        message.warning('Attachment can not change after submission.');
        return
      }
      if(fileList.value.length<=0){
        message.warning('you have not uploaded any attachments');
        return
      }
      if(fileList.value.length>state.payment.maxFileCount){
        message.warning(`Attachment batch upload can not more than ${state.payment.maxFileCount}.`);
        return
      }
      const isMore5m = isLt5M.value.every(( item )=>{
          return item;
      })
      if(!isMore5m){
        message.warning(`Attachment can not large than ${state.payment.maxFileSize}M.`);
        return
      }
      fileList.value.forEach((file: FileItem) => {
        file.name = props.payoutCode+'_'+file.name
        formData.append('files', file.originFileObj as any);
      });
      uploading.value = true;
      const params = {
        paymentId: props.paymentId,
        file: formData
      }
     /* uploadAttachment({}, props.paymentId, formData).then((res)=>{
        console.log('xxxxxxxxxxxxx',res)
      });*/
      axios({
        method: 'post',
        data: formData,
        url: '/ptapi/overview/uploadFile?paymentId='+props.paymentId,
       /* params: params*/
      }).then(res => {
        fileList.value = [];
        uploading.value = false;
        if (res.data.code !== '0') {
          Modal.error({
            title: 'Tips',
            content: 'Upload failed'//res.data.message
          })
          // 存data里的信息
          if (res.data.data !== '') window.sessionStorage.setItem('errorFileName',res.data.data)
          // window.sessionStorage.getItem("username")
        } else {
          Modal.info({
            title: 'Tips',
            content: 'Successfully Upload！'//res.data.message
          })
          getUploadFileData(props.paymentId).then((res) => {
            myAttachmentData.value = res
          })
        }
      })
    }

    // const handleUpload = () => {
    //   submitFile(fileList)
    // }
    const deleteAttachment = (record: any)=> {
      if(props.payStatus !== 'Created'){
        message.warning('only Created status can delete attachments.');
        return
      }
      delAttachment({params:{
        fileId:record.id
      }}).then(( res )=>{
        if(res.code == '0')
        Modal.info({
          content: 'Sucessfully Delete!'//res.message
        })
        else {
          Modal.error({
            title: 'Tips',
            content: 'Delete failed'
          })
        }
        getUploadFileData(props.paymentId).then((res) => {
          myAttachmentData.value = res
        })
      })
    }
    const downloadAttachment = (record: any)=>{
      const params = {
        url: '/ptapi/downloadFile?fileId='+record.id,
        method: 'get',
        fileName: record.fileName
      }
      downloadFile(params)
    }
    return{
      myAttachmentData,
      fileList,
      title,
      modal,
      roleVal,
      close,
      isLt5M,
      submitFile,
      beforeUpload,
      handleRemove,
      uploadComplete,
      attachmentColumns,
      deleteAttachment,
      downloadAttachment

    }
  },
})
