
import { defineComponent, ref, onMounted, provide, onUpdated, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { btnPermissions } from "@/utils";
export const mountedKey = Symbol()
export default defineComponent({
  name: "Dealer",
  props: {},
  setup() {
    const activeKey = ref<string>("1");
    const route = useRoute();
    if (route.name === "Payment Bu Management F&L") {
      activeKey.value = "2";
    } else {
      activeKey.value = "1";
    }
    onUpdated(() => {
      if (route.name === "Payment Bu Management F&L") {
        activeKey.value = "2";
      } else {
        activeKey.value = "1";
      }
    });
    const mounted = ref(false)
    provide(mountedKey, mounted)
    onMounted(() => {
      mounted.value = true
    })

    const router = useRouter();
    // 点击tab
    const tabClick = (tab: string) => {
      switch (tab) {
        case "1":
          router.push({ path: "/buSiPayoutApplication" });
          break;
        case "2":
          router.push({ path: "/paymentBuManagement/FAndL" });
          break;
      }
    };

    const showRouterView = computed(() => {
      switch (route.name) {
        case 'Payment Bu Management Dealer':
          return btnPermissions('BSPA0002', '/buSiPayoutApplication')
        case 'Payment Bu Management F&L':
          return btnPermissions('BSPA0003', '/buSiPayoutApplication')
        default:
          return true
      }
    })

    return {
      activeKey,
      tabClick,
      showRouterView
    };
  },
});
