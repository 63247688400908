import { ref, reactive } from "vue";
import { MBAFCInitSelectedDataForSearch, MBAFCTableData,AttachmentData, RowSelectionData} from "@/views/Payment/FlRt/types";
import * as API from "@/API/payment";
import { useStore } from 'vuex'
import downloadFile from '@/utils/payment/downloadFile'
import { ApproveData } from '@/views/Payment/types'
import { message } from 'ant-design-vue';
const useMBAFCOverview = () => {
  const store = useStore()
  const state = reactive({
    selectedRowKeys: [], // Check here to configure the default column
    selectedRows: [],
    year: '',
    status: '',
    payoutRound: '',
    pageNumber:1,
    pageSize:10
    //secondPayountRound: payoutRoundData[year][0],
  });
  // 打开筛选条件
  const isOpen = ref<boolean>(false)
  // 筛选面板
  const activeKey = ref<string>("")
  // 校验列表
  const tableData = ref<MBAFCTableData[]>([])
  const total = ref<number>(0)
  // 上传的附件列表
  const attachmentData = ref<AttachmentData[]>([])

  // 是否显示失败原因面板
  const showFailFlag = ref<boolean>(false)
  // 状态下拉框
  //const approvalStatusArray = ref<DealerStatusData[]>([])
  // 激励适用性
 // const rebateFlagArray = ref<DealerStatusData[]>([])
  // 批量查询
  const batchQuery = reactive({
    batchQueryTitle: '',
    batchQueryModal: false
  })
/*  const columns = reactive([
    { title: "Payout Round", dataIndex: "payoutRound", width: 154 },
    { title: "Payout Code", dataIndex: "payoutCode", key: "model", width: 154 },
    { title: "Vin No.", dataIndex: "vinNo", key: "modelYear", width: 100 },
    { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat",  width: 100},
    { title: "Status", dataIndex: "status", width: 110, key: "usage" },
    { title: "Last Update Date", dataIndex: "updatedAtCast", width: 90, key: "retailDate" },
    { title: "Operation", key: "operation", fixed: 'right', width: 150, slots: { customRender: "action"}
    },
])*/
  const columns = reactive([
    { title: "Payout Round", dataIndex: "payoutRound", width: 150},
    { title: "Payout Code", className: 'column-no-wrap', dataIndex: "payoutCode", key: "model", width: 150},
    { title: "Vin No.", dataIndex: "vinNo", key: "modelYear", width: 80, align: 'right' },
    { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat",  width: 160, align: 'right'},
    { title: "", dataIndex: "blank", width: 50},
    { title: "Status", dataIndex: "status", width: 110, key: "usage"},
    { title: "Last Update Date", dataIndex: "updatedAtCast", width: 150, key: "retailDate"},
    { title: "Operation", key: "operation", fixed: 'right',  align: 'left',width: 100, slots: { customRender: "action"}
    },
  ])
  // 查询区数据：包括 Year|Payout Round|Payount Status
  const initSelectedDataForS = reactive<MBAFCInitSelectedDataForSearch>({
    year:[],
    payoutRound:[],
    status:[]
  });
  // 发票/行驶证 查看弹窗
  const pictureModal = reactive({
    pictureModalFlag: false,
    pictureModalTitle: "",
    pictureModalImg: "",
  });
  // 筛选条件
  const queryParams = reactive({
    /*year:"",
    payoutCode:"",
    payStatus:"",
    currentPage:1,
    pageSize:20*/
  });
  const getTableData = () => {
    const params = {
      ...state, userId: store.state.user.usrid
    }
    if (!state.status){
      params.status = "all"
    }
    console.log('列表的查询条件是：',params)
    API.getMBAFCTable(params).then((res: any) => {
      console.log('返回列表数据：',res)
      let myContent: MBAFCTableData[]  = [];
      myContent = res.content;
      myContent.map((item: MBAFCTableData)=>{//vinNo 千分位加逗号
        //console.log(typeof item.vinNo)
        item.vinNo = item.vinNo.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,');
        return  item
      })
      tableData.value = myContent;
      total.value = res.totalElements;
      state.pageSize = res.size;
    });
  }
  //获取MBAFC查询区域的数据
  const getMBAFCInitSelectedDataForSearch = async (role: string) => {
    // status下拉框数据
    const res = await API.getInitSelectedDataForSearch({params: {role}})
    const statusTemp = res.status
    const statusR = statusTemp.map((value: string,key: string,arr: Array<any>) => {
      const statusObj = Object.assign({},value)
      statusObj['code'] = value['value']
      statusObj['value'] = value['value']
      return statusObj
    })
    console.log('返回查询区下拉框数据：',res)

    // year下拉框数据
    const yearRes = await API.getRoundYearList()

    initSelectedDataForS.year = yearRes
    initSelectedDataForS.status = statusR;
    if (initSelectedDataForS.year.length === 0){
      state.year = ''
    }
    else {
      state.year = initSelectedDataForS.year[0]
    }
    // state.status = initSelectedDataForS.status[0].value

    // round下拉框数据
    // getPayountRoundByYearForSearch(state.year)
    getTableData()
  }
  
  //点击查询区“年”，获取payoutRound数据
  const getPayountRoundByYearForSearch = async(year: string) => {
    if (year) {
      const params = {
        year: year
      }
      const res = await API.getRoundFinanceList({params})
      // const res = await API.getPayountRoundByYearForSearch({params:{
      //   year: year, role: store.state.user.fulnm}})
      const legalEntityMBAFC = store.state.user?.legalEntity.entityName === 'MBAFC'
      const legalEntityMBLC = store.state.user?.legalEntity.entityName === 'MBLC'
      initSelectedDataForS.payoutRound = res.filter((payoutRound: string) => {
        if (!legalEntityMBAFC && !legalEntityMBLC) {
          return true
        }
        if (legalEntityMBAFC && payoutRound.indexOf('_AFC_') >= 0) {
          return true
        }
        if (legalEntityMBLC && payoutRound.indexOf('_LC_') >= 0) {
          return true
        }
        return false
      })
      state.payoutRound = initSelectedDataForS.payoutRound.length ? initSelectedDataForS.payoutRound[0] : ''
      getTableData()
    } else {
      initSelectedDataForS.payoutRound = []
    }
    
  }/*
  const resetEvent = () => {
  };*/
  const getUploadFileData = (id: string) =>{
    return API.getUploadFileData({params:{
        paymentId:id
      }}).then((res)=>{
        console.log('返回附件列表：',res)
      attachmentData.value = res
         return attachmentData.value = res
      });
  }
    const downloadImg = () => {
    const imgUrl = `data:image/jpg;base64,${pictureModal.pictureModalImg}`;
    // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
    if (window.navigator.msSaveOrOpenBlob) {
      const bstr = atob(imgUrl.split(",")[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      const blob = new Blob([u8arr])
      window.navigator.msSaveOrOpenBlob(blob, `${pictureModal.pictureModalTitle}.jpg`)
    } else {
      // 这里就按照chrome等新版浏览器来处理
      const a = document.createElement("a");
      a.href = imgUrl;
      a.setAttribute("download", `${pictureModal.pictureModalTitle}.jpg`)
      a.click();
    }
  };
  const resetParam = () => {
    /*queryParams.retailStartDate = queryParams.retailStartDate ? queryParams.retailStartDate : ""
    queryParams.retailEndDate = queryParams.retailEndDate ? queryParams.retailEndDate : ""
    queryParams.invoiceStartDate = queryParams.invoiceStartDate ?
     queryParams.invoiceStartDate : ""
    queryParams.invoiceEndDate = queryParams.invoiceEndDate ? queryParams.invoiceEndDate : ""
    queryParams.licenseStartDate = queryParams.licenseStartDate ? 
    queryParams.licenseStartDate : ""
    queryParams.licenseEndDate = queryParams.licenseEndDate ? queryParams.licenseEndDate : ""
    queryParams.modelYear = queryParams.modelYear ? queryParams.modelYear : ""
    queryParams.approlStatus = queryParams.approlStatus ? queryParams.approlStatus : ""
    queryParams.specialUsage = queryParams.specialUsage ? queryParams.specialUsage : ""
    queryParams.checkStatus = queryParams.checkStatus ? queryParams. checkStatus : ""
    queryParams.usage = queryParams.usage ? queryParams.usage : ""
    queryParams.powerTrain =  queryParams.powerTrain ? queryParams.powerTrain : ""
    queryParams.typeClass = queryParams.typeClass ? queryParams.typeClass : ""
    queryParams.entity = queryParams.entity ? queryParams.entity : ""
    queryParams.vinNo = queryParams.vinNo ? queryParams.vinNo : ""
    queryParams.model = queryParams.model ? queryParams.model : ""
    queryParams.commissionNo = queryParams.commissionNo ? queryParams.commissionNo : ""*/
  }
  const mulSearchEvent = (rowSelection: any) => {
    // if(!state.status){
    //   state.status = "all"
    // }
    state.year = state.year ? state.year : ""
    state.status = state.status ? state.status : ""
    state.payoutRound = state.payoutRound ? state.payoutRound : ""
    state.pageNumber = 1
    rowSelection.selectedRowKeys = []
    rowSelection.selectedRowData = []
    getTableData()
  }
  const overviewSerachEvent = (str: string) => {/*
    isOpenPanle.value = false
    isOpen.value = false
    activeKey.value = ""
    resetEvent()
    switch (str) {
      case "leftRetialTotal":
        queryParams.retailStartDate = overview.value.currRetailMonth + "/01";
        break;
      case "leftNotSubmit":
        queryParams.retailStartDate = overview.value.currRetailMonth + "/01";
        queryParams.approlStatus = "0";
        break;
      case "leftcheckStatus":
        queryParams.retailStartDate = overview.value.currRetailMonth + "/01";
        queryParams.checkStatus = "3333";
        break;
      case "rightRetialTotal":
        queryParams.retailStartDate = overview.value.lastRetailMonth + "/01";
        break;
      case "rightNotSubmit":
        queryParams.retailStartDate = overview.value.lastRetailMonth + "/01";
        queryParams.approlStatus = "0";
        break;
      case "rightcheckStatus":
        queryParams.retailStartDate = overview.value.lastRetailMonth + "/01";
        queryParams.checkStatus = "3333";
        break;
    }
    queryParams.retailEndDate = getMonthLast(queryParams.retailStartDate)
    mulSearchEvent()*/
  };
  
  const licenseEvent = (vinNo: string) => {
   /* const params = { vinNo: vinNo };
    API.getlicenseView({ params }).then(res => {
      pictureModal.pictureModalFlag = true;
      pictureModal.pictureModalTitle = "RE_" + vinNo
      pictureModal.pictureModalImg = res;
    });*/
  };
  const invoiceEvent = (vinNo: string) => {
    const params = { vinNo: vinNo };
    API.getInvioceView({params}).then(res=>{
      pictureModal.pictureModalFlag = true;
      pictureModal.pictureModalImg = res;
      pictureModal.pictureModalTitle = "IN_"+ vinNo;
    });
  } 
  
  const pageChange = (page: number, pageSize: number,rowSeclection: RowSelectionData) => {
    state.pageNumber = page > 0 ? page : 1
    state.pageSize = pageSize;
    rowSeclection.selectedRowKeys = []
    getTableData()
  };
  const sizeChange = (page: number, pageSize: number,rowSelection: RowSelectionData) => {
    state.pageNumber = page > 0 ? page : 1
    state.pageSize = pageSize;
    rowSelection.selectedRowKeys = []
    getTableData()
  };
  const handleClick = (event: MouseEvent) => {
    //isOpenPanle.value = false;
    activeKey.value = ""
    event.stopPropagation();
  };
  const handleActiveKey = () => {
    if (activeKey.value.indexOf("3") !== -1) {
//      showCollapsed.value = true;
    } else {
      //showCollapsed.value = false;
    }
  };
  const downloadFilesClick = () => {
    const params = {
      url: `/ptapi/clickPayoutRound/${state.payoutRound}`,
      method: 'get',
      params: {}
    }
    downloadFile(params).then(() => {
        // loading.value = false;
        // downloadModalVisible.value = false;
        // emptyVisible.value = false;
    })
  }

  const approveList = ref<ApproveData[]>()
  const approveVisible = ref(false)
  const approveClick = (record: any) => {
      approveList.value = []
      // API.getApprovalPackage(record.id).then((res: any) => {
      //   if (res.code === '0') {
      //     approveList.value = res.data.nodeList
      //     approveVisible.value = true
      //   } else {
      //     message.error(res.message)
      //   }
      // })
      approveList.value = []
      const params = {
        approvalType: "PayoutApproval",
        payoutCodeId: record.id,
        payoutType: "FL"
      }
      API.getApprovalPackageByCreated(params).then((res: any) => {
          if (res.code === '0') {
              approveList.value = res.data.map((item: any) => item.nodeList)
              approveVisible.value = true
          } else {
              message.error(res.message)
          }
      })
  }

  return {
    resetParam,
    getTableData,
    //resetEvent,
    mulSearchEvent,
    downloadImg,
    pageChange,
    sizeChange,
    licenseEvent,
    invoiceEvent,
    handleClick,
    handleActiveKey,
    overviewSerachEvent,
    getMBAFCInitSelectedDataForSearch,
    initSelectedDataForS,
    getPayountRoundByYearForSearch,
    getUploadFileData,
    columns,
    attachmentData,
    queryParams,
    //overview,
//    isOpenPanle,
    isOpen,
    tableData,
    total,
    pictureModal,
    activeKey,
    showFailFlag,
//    showCollapsed,
    batchQuery,
    state,
    //approvalStatusArray,
    //rebateFlagArray,
    downloadFilesClick,
    approveVisible,
    approveList,
    approveClick
  }
}
export default useMBAFCOverview